body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .xyz{
  padding-left: 20px;
  padding-right: 20px;
} */

/* **************************************************HOME DESIGN************************************* */
/* img{
  overflow: hidden;
  position: relative;
  width: 100%;
}*/

/* @media (min-width: 0px) and (max-width: 500px){
  .content-on-image-in-homepage-and-button{
    position: relative;
    left: 15px;
    bottom: 190px;
    color: white;
    font-size:18px;
  }
}

@media (min-width: 501px) and (max-width: 570px){
  .content-on-image-in-homepage-and-button{
    position: relative;
    left: 15px;
    bottom: 170px;
    color: white;
    font-size:18px;
  }
}



@media (min-width: 571px) and (max-width: 650px){
  .content-on-image-in-homepage-and-button{
    position: relative;
    left: 15px;
    bottom: 170px;
    color: white;
    font-size:20px;
  }
}

@media (min-width: 651px) and (max-width: 800px){
  .content-on-image-in-homepage-and-button{
    position: relative;
    left: 15px;
    bottom: 220px;
    color: white;
    font-size:24px;
  }
}

@media (min-width: 801px) and (max-width: 950px){
  .content-on-image-in-homepage-and-button{
    position: relative;
    left: 20px;
    bottom: 220px;
    color: white;
    font-size:25px;
  }
}

@media (min-width: 951px) and (max-width: 1100px){
  .content-on-image-in-homepage-and-button{
    position: relative;
    left: 40px;
    bottom: 220px;
    color: white;
    font-size:25px;
  }
}


@media (min-width: 1101px) and (max-width: 1350px){
  .content-on-image-in-homepage-and-button{
    position: relative;
    left: 60px;
    bottom: 360px;
    color: white;
    font-size:30px;
  }
}

@media (min-width:1351px){
  .content-on-image-in-homepage-and-button{
    position: relative;
    left: 75px;
    bottom: 380px;
    color: white;
    font-size:30px;
  }
}  */

.home {
  margin: 0;
  padding: 0;
}

.banner {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url('./assets/images/image1.png');
  background-size: cover;
  background-position: center;
}

.content {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  color: white;
}

.content h1 {
  color: rgb(148, 133, 92);
  font-size: 70px;
  margin-top: 80px;
  color: white;
}

.content p {
  color: rgb(255, 255, 255);
  margin: 20px auto;
  font-weight: 100;
  line-height: 25px;
}

.main-button {
  width: 200px;
  padding: 14px 25px;
  text-align: center;
  margin: 20px 10px;
  border-radius: 25px !important;
  font-weight: bold;
  border: 2px solid #009688;
  background: transparent;
  color: white;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

span {
  padding: 0px 0px 0px 0px;
  border-radius: 0;
}

.span721 {
  border: 2px solid transparent;
  background: #009688;
  height: 100%;
  width: 0;
  border-radius: 25px;
  position: absolute;
  left: -2%;
  bottom: 0;
  z-index: -1;
  transition: 0.5s;
}

.main-button:hover span {
  width: 102%;
}

.main-button:hover {
  border: 2px solid transparent;
}

/* ***************************************FOR RESPONSIVE NAVBAR************************************************ */
@media (min-width: 0px) and (max-width: 991px) {
  .navbar-1 {
    display: block;
  }
  .navbar-2 {
    display: none;
  }
  .navbar-3 {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1005px) {
  .navbar-1 {
    display: none;
  }
  .navbar-2 {
    display: block;
  }
  .navbar-3 {
    display: none;
  }
}
@media (min-width: 1006px) and (max-width: 1085px) {
  .navbar-1 {
    display: none;
  }
  .navbar-2 {
    display: none;
  }
  .navbar-3 {
    display: block;
  }
}
@media (min-width: 1086px) and (max-width: 1189px) {
  .navbar-1 {
    display: none;
  }
  .navbar-2 {
    display: none;
  }
  .navbar-3 {
    display: block;
  }
}
@media (min-width: 1190px) {
  .navbar-1 {
    display: block;
  }
  .navbar-2 {
    display: none;
  }
  .navbar-3 {
    display: none;
  }
}

/* pre{
  margin-bottom: 0;
} */

.car {
  padding: 0px;
}

/* .home-container{
  position: relative;
  padding-left:0px;
  padding-right:0px;
} */

.medium-screen {
  display: none;
}

.row2 {
  color: white;
}

.hr {
  border-color: rgb(146, 140, 140);
}

@media (min-width: 0px) and (max-width: 576px) {
  .small-screen {
    width: 100%;
  }
  .medium-screen {
    display: none;
  }
  .large-screen {
    display: none !important;
  }
  .hr {
    border-width: 2px;
    border-color: rgb(146, 140, 140);
  }
}

@media (min-width: 576px) {
  .small-screen {
    display: none;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .medium-screen {
    display: block;
  }
  .large-screen {
    display: none !important;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap');

.every-title {
  margin-bottom: 0px;
  font-family: 'Fredericka the Great', cursive;
  font-size: 25px;
  font-weight: 700;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.card-title {
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
.body-text {
  font-family: 'Open Sans', sans-serif;
}

.large-screen {
  background-color: #343a40;
  color: white;
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
.logoofsyntax {
  font-family: 'Permanent Marker', cursive;
}

/* ************************************BeforeAfterButoon************************************ */
.beforeafterbutton {
  background: #343a40;
  background-image: -webkit-linear-gradient(top, #343a40, #343a40);
  background-image: -moz-linear-gradient(top, #343a40, #343a40);
  background-image: -ms-linear-gradient(top, #343a40, #343a40);
  background-image: -o-linear-gradient(top, #343a40, #343a40);
  background-image: linear-gradient(to bottom, #343a40, #343a40);
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  border-radius: 6px;
  font-family: Arial;
  color: #ffffff;
  font-size: 30px;
  padding: 0px 30px 0px 30px;
  text-decoration: none;
}

.beforeafterbutton:hover {
  background: #3cb0fd;
  background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
  background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
  text-decoration: none;
}

/* ************************************BeforeAfterButoon for Break/Continue************************************ */

@media (min-width: 0px) and (max-width: 506px) {
  .beforeafterbutton {
    background: #343a40;
    background-image: -webkit-linear-gradient(top, #343a40, #343a40);
    background-image: -moz-linear-gradient(top, #343a40, #343a40);
    background-image: -ms-linear-gradient(top, #343a40, #343a40);
    background-image: -o-linear-gradient(top, #343a40, #343a40);
    background-image: linear-gradient(to bottom, #343a40, #343a40);
    -webkit-border-radius: 6;
    -moz-border-radius: 6;
    border-radius: 6px;
    font-family: Arial;
    color: #ffffff;
    font-size: 20px;
    padding: 9px 10px 9px 10px;
    text-decoration: none;
  }

  .beforeafterbutton:hover {
    background: #3cb0fd;
    background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
    background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
    text-decoration: none;
  }
}

.navbar-1 .nav-link,
.navbar-2 .nav-link,
.navbar-3 .nav-link {
  color: hsla(0, 0%, 100%, 0.5);
}

.navbar-1 .nav-link:hover,
.navbar-2 .nav-link:hover,
.navbar-3 .nav-link:hover {
  color: hsla(0, 0%, 100%, 0.75);
}

.navbar-1 .nav-link:active,
.navbar-2 .nav-link:active,
.navbar-3 .nav-link:active {
  color: hsla(0, 0%, 100%, 1) !important;
}

.navbar-1 .nav-link:focus,
.navbar-2 .nav-link:focus,
.navbar-3 .nav-link:focus {
  color: hsla(0, 0%, 100%, 1);
}
.navbar-1 .navbar-brand,
.navbar-2 .navbar-brand,
.navbar-3 .navbar-brand {
  color: #fff;
  padding-left: 0.75em;
}

.navbar-1 .navbar-brand:hover,
.navbar-2 .navbar-brand:hover,
.navbar-3 .navbar-brand:hover,
.navbar-1 .navbar-brand:active,
.navbar-2 .navbar-brand:active,
.navbar-3 .navbar-brand:active {
  color: #fff;
}

.navbar-toggler-icon {
  background-image: url('./assets/svg/hamburger-menu.svg') !important;
}

.navbar-1 .navbar-toggler,
.navbar-2 .navbar-toggler,
.navbar-3 .navbar-toggler {
  outline: none;
  margin-right: 0.5em;
  border: none !important;
}

.navbar-1 .navbar-toggler:active,
.navbar-2 .navbar-toggler:active,
.navbar-3 .navbar-toggler:active {
  outline: none;
}

.navbar-1 .navbar-toggler:focus,
.navbar-2 .navbar-toggler:focus,
.navbar-3 .navbar-toggler:focus {
  box-shadow: none !important;
}
